<template>
  <div class="site-full flex items-center justify-center p-6">
    <div class="heading-container mb-10">
      <h2 class="text-5xl font-bold text-center text-primary mb-6">
        AI Text to Speech
      </h2>
    </div>
    <div
      class="w-full sm:w-full md:w-3/4 lg:w-1/2 xl:w-1/2 max-w-2xl p-6 bg-muted/90 shadow-lg rounded-lg"
    >

      <!-- Textarea -->
      <textarea
        rows="15"
        v-model="text"
        class="h-400px w-full px-4 py-2 bg-muted text-primary border border-gray-600 rounded-md focus:ring-red-500 focus:border-red-500 block sm:text-sm mb-4"
        placeholder="Type something..."
      ></textarea>

      <!-- Voice Selection and Convert Button -->
      <div
        class="flex justify-between items-center mb-4 flex-col md:flex-row gap-3"
      >
        <select
          v-model="selectedVoice"
          class="w-full md:w-max px-4 py-2 bg-muted/10 text-primary border border-gray-600 rounded-md focus:ring-red-500 focus:border-red-500"
        >
          <option
            v-for="(voice, index) in voices"
            :key="index"
            :value="voice.value"
          >
            {{ voice.name }}
          </option>
        </select>
        <button
          @click="submitText"
          class="w-full justify-between md:w-max flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary focus:outline-none hover:bg-black"
        >
          <span> Convert to Speech </span>
          <span v-if="loading" class="spinner"></span>
        </button>
      </div>

      <!-- Audio Player -->
      <audio ref="audioPlayer" controls class="mt-4 w-full bg-muted"></audio>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      audioUrl: null,
      selectedVoice: "alloy",
      loading: false,
      voices: [
        { name: "Alloy", value: "alloy" },
        { name: "Echo", value: "echo" },
        { name: "Fable", value: "fable" },
        { name: "Onyx", value: "onyx" },
        { name: "Nova", value: "nova" },
        { name: "Shimmer", value: "shimmer" },
      ],
    };
  },
  methods: {
    async submitText() {
      this.loading = true;
      if (this.text === "") {
        alert("Please enter some text to convert to speech");
        this.loading = false;
        return;
      }
      try {
        // get the current domain
        const apiDomain = "https://voice-api.fly.dev";
        const response = await fetch(`${apiDomain}/api/text-to-speech`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ text: this.text, voice: this.selectedVoice }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Create a Blob from the MP3 stream
        const blob = await response.blob();
        // Create an object URL for the Blob
        const audioUrl = URL.createObjectURL(blob);

        // Play the audio
        this.$refs.audioPlayer.src = audioUrl;
        this.$refs.audioPlayer.play();
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.loading = false;
      }
    },
    finalAudioUrl(url) {
      // get the site url
      console.log(url);
      const siteUrl = window.location.origin;
      // return the full url
      this.audioUrl = `${siteUrl}${url}`;
    },
  },
};
</script>

<style>
/* Add some basic styles for the loading spinner */
.spinner {
  animation: spin 1s linear infinite;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #222;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: 20px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
