<template>
      <svg
      viewBox="0 0 398.55 63.6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="['pt-4 pb-4 pr-4', className, invertedClass]"
    >
      <defs></defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path
            class="innomactic-logo-brand"
            d="M14.33,7.11c0,.99-.19,1.91-.57,2.77-.38.86-.89,1.61-1.54,2.25-.65.64-1.41,1.15-2.28,1.52-.87.38-1.8.57-2.79.57s-1.92-.19-2.79-.57c-.87-.38-1.63-.88-2.26-1.52-.64-.64-1.15-1.39-1.52-2.25-.38-.86-.57-1.78-.57-2.77s.19-1.88.57-2.75c.38-.87.88-1.63,1.52-2.26.64-.64,1.39-1.15,2.26-1.52.87-.38,1.8-.57,2.79-.57s1.92.19,2.79.57c.87.38,1.63.89,2.28,1.52.65.64,1.16,1.39,1.54,2.26.38.87.57,1.79.57,2.75Z"
          />
          <rect
            class="innomactic-logo-main"
            x="1.76"
            y="18.47"
            width="10.74"
            height="41.82"
          />
          <path
            class="innomactic-logo-main"
            d="M31.28,60.29h-10.66V18.47h2.58l3.51,4.06c1.72-1.56,3.66-2.77,5.84-3.61,2.17-.85,4.44-1.27,6.81-1.27,2.55,0,4.96.49,7.22,1.46,2.27.98,4.24,2.32,5.94,4.02,1.69,1.71,3.03,3.7,4,5.97.98,2.28,1.46,4.71,1.46,7.28v23.9h-10.66v-23.9c0-1.09-.21-2.13-.63-3.1s-.99-1.83-1.72-2.56c-.73-.73-1.58-1.3-2.54-1.72-.96-.42-1.99-.62-3.08-.62s-2.17.21-3.16.62c-.99.42-1.85.99-2.58,1.72-.73.73-1.3,1.58-1.72,2.56-.42.98-.63,2.01-.63,3.1v23.9Z"
          />
          <path
            class="innomactic-logo-main"
            d="M76.22,60.29h-10.66V18.47h2.58l3.51,4.06c1.72-1.56,3.66-2.77,5.84-3.61,2.17-.85,4.44-1.27,6.81-1.27,2.55,0,4.96.49,7.22,1.46,2.27.98,4.24,2.32,5.94,4.02,1.69,1.71,3.03,3.7,4,5.97.98,2.28,1.46,4.71,1.46,7.28v23.9h-10.66v-23.9c0-1.09-.21-2.13-.62-3.1-.42-.98-.99-1.83-1.72-2.56-.73-.73-1.58-1.3-2.54-1.72-.96-.42-1.99-.62-3.08-.62s-2.17.21-3.16.62c-.99.42-1.85.99-2.58,1.72-.73.73-1.3,1.58-1.72,2.56-.42.98-.62,2.01-.62,3.1v23.9Z"
          />
          <path
            class="innomactic-logo-main"
            d="M212.99,60.29h-10.62v-26.55c0-.73-.14-1.42-.41-2.07-.27-.65-.66-1.22-1.15-1.72-.49-.49-1.07-.88-1.72-1.15-.65-.27-1.34-.41-2.07-.41s-1.41.14-2.05.41c-.64.27-1.2.66-1.68,1.15-.48.49-.86,1.07-1.13,1.72-.27.65-.41,1.34-.41,2.07v26.55h-10.66v-26.55c0-.73-.14-1.42-.41-2.07-.27-.65-.66-1.22-1.15-1.72-.49-.49-1.07-.88-1.72-1.15-.65-.27-1.34-.41-2.07-.41s-1.41.14-2.05.41c-.64.27-1.2.66-1.68,1.15-.48.49-.86,1.07-1.13,1.72-.27.65-.41,1.34-.41,2.07v26.55h-10.66V18.47h2.58l2.89,3.2c1.48-1.25,3.12-2.22,4.9-2.91,1.78-.69,3.64-1.03,5.56-1.03s3.86.36,5.72,1.09c1.86.73,3.49,1.99,4.9,3.79.65-.88,1.39-1.64,2.23-2.26.83-.62,1.71-1.13,2.64-1.52.92-.39,1.88-.67,2.87-.84.99-.17,1.97-.25,2.93-.25,2.21,0,4.29.42,6.23,1.25,1.94.83,3.63,1.97,5.08,3.42,1.44,1.44,2.58,3.14,3.42,5.1.83,1.95,1.25,4.03,1.25,6.25v26.55Z"
          />
          <path
            class="innomactic-logo-main"
            d="M259.26,60.29h-2.58l-4.14-5.74c-1.02.91-2.09,1.76-3.22,2.56-1.13.79-2.32,1.48-3.57,2.07-1.25.59-2.53,1.05-3.85,1.39-1.31.34-2.65.51-4,.51-2.94,0-5.71-.49-8.3-1.48-2.59-.99-4.85-2.43-6.79-4.31-1.94-1.89-3.47-4.2-4.59-6.93-1.12-2.73-1.68-5.84-1.68-9.33,0-3.25.56-6.24,1.68-8.96,1.12-2.72,2.65-5.06,4.59-7.01,1.94-1.95,4.2-3.47,6.79-4.55,2.59-1.08,5.36-1.62,8.3-1.62,1.35,0,2.69.17,4.02.51,1.33.34,2.62.81,3.87,1.41,1.25.6,2.44,1.3,3.57,2.11,1.13.81,2.19,1.68,3.18,2.62l4.14-4.96h2.58v41.74ZM248.52,39.01c0-1.46-.28-2.87-.84-4.24-.56-1.37-1.32-2.58-2.28-3.63s-2.09-1.9-3.38-2.54c-1.29-.64-2.66-.96-4.12-.96s-2.83.25-4.12.74c-1.29.49-2.41,1.22-3.36,2.19-.95.96-1.7,2.15-2.25,3.57-.55,1.42-.82,3.04-.82,4.86s.27,3.44.82,4.86c.55,1.42,1.29,2.61,2.25,3.57.95.96,2.07,1.69,3.36,2.19,1.29.49,2.66.74,4.12.74s2.83-.32,4.12-.96c1.29-.64,2.41-1.48,3.38-2.54.96-1.05,1.72-2.26,2.28-3.63.56-1.37.84-2.78.84-4.24Z"
          />
          <path
            class="innomactic-logo-main"
            d="M302.93,26.79l-7.81,7.85c-.39-1.09-.92-2.08-1.6-2.95-.68-.87-1.44-1.61-2.28-2.21-.85-.6-1.76-1.05-2.75-1.37-.99-.31-2-.47-3.05-.47-1.46,0-2.83.3-4.12.9-1.29.6-2.41,1.44-3.36,2.52-.95,1.08-1.7,2.36-2.25,3.85s-.82,3.12-.82,4.92c0,1.46.27,2.82.82,4.1.55,1.28,1.29,2.4,2.25,3.36.95.96,2.07,1.72,3.36,2.26,1.29.55,2.66.82,4.12.82,1.04,0,2.04-.14,3.01-.43.96-.29,1.87-.7,2.71-1.23.85-.53,1.6-1.18,2.26-1.95.66-.77,1.2-1.62,1.62-2.56l7.81,7.85c-.99,1.41-2.13,2.67-3.42,3.79-1.29,1.12-2.68,2.07-4.18,2.85-1.5.78-3.07,1.37-4.72,1.78-1.65.4-3.35.61-5.1.61-2.94,0-5.71-.55-8.3-1.66-2.59-1.11-4.86-2.62-6.79-4.55-1.94-1.93-3.47-4.18-4.59-6.76-1.12-2.58-1.68-5.34-1.68-8.28,0-3.2.56-6.2,1.68-8.98,1.12-2.78,2.65-5.21,4.59-7.28,1.94-2.07,4.2-3.7,6.79-4.9,2.59-1.2,5.36-1.8,8.3-1.8,1.74,0,3.46.22,5.13.66,1.68.44,3.28,1.09,4.8,1.93,1.52.85,2.93,1.88,4.22,3.1,1.29,1.22,2.4,2.63,3.34,4.22Z"
          />
          <path
            class="innomactic-logo-main"
            d="M329.09,60.29c-2.58,0-5-.49-7.26-1.46-2.27-.98-4.25-2.32-5.95-4.02-1.71-1.7-3.05-3.69-4.02-5.95-.98-2.26-1.46-4.69-1.46-7.26v-12.42h-5.19v-10.62h5.19V1.84h10.62v16.71h16.17v10.62h-16.17v12.42c0,1.12.21,2.17.62,3.14.42.98.99,1.83,1.72,2.56.73.73,1.59,1.31,2.58,1.74.99.43,2.04.64,3.16.64h8.08v10.62h-8.08Z"
          />
          <path
            className="innomactic-logo-brand"
            d="M355.72,7.11c0,.99-.19,1.91-.57,2.77s-.89,1.61-1.54,2.25c-.65.64-1.41,1.15-2.28,1.52-.87.38-1.8.57-2.79.57s-1.92-.19-2.79-.57c-.87-.38-1.63-.88-2.27-1.52-.64-.64-1.15-1.39-1.52-2.25-.38-.86-.57-1.78-.57-2.77s.19-1.88.57-2.75c.38-.87.88-1.63,1.52-2.26.64-.64,1.39-1.15,2.27-1.52.87-.38,1.8-.57,2.79-.57s1.92.19,2.79.57c.87.38,1.63.89,2.28,1.52.65.64,1.16,1.39,1.54,2.26.38.87.57,1.79.57,2.75Z"
          />
          <rect
            class="innomactic-logo-main"
            x="343.14"
            y="18.47"
            width="10.74"
            height="41.82"
          />
          <path
            class="innomactic-logo-main"
            d="M398.55,26.79l-7.81,7.85c-.39-1.09-.92-2.08-1.6-2.95-.68-.87-1.44-1.61-2.28-2.21-.85-.6-1.76-1.05-2.75-1.37-.99-.31-2-.47-3.05-.47-1.46,0-2.83.3-4.12.9-1.29.6-2.41,1.44-3.36,2.52-.95,1.08-1.7,2.36-2.25,3.85-.55,1.48-.82,3.12-.82,4.92,0,1.46.27,2.82.82,4.1.55,1.28,1.29,2.4,2.25,3.36.95.96,2.07,1.72,3.36,2.26,1.29.55,2.66.82,4.12.82,1.04,0,2.04-.14,3.01-.43.96-.29,1.87-.7,2.71-1.23.85-.53,1.6-1.18,2.26-1.95.66-.77,1.2-1.62,1.62-2.56l7.81,7.85c-.99,1.41-2.13,2.67-3.42,3.79-1.29,1.12-2.68,2.07-4.18,2.85-1.5.78-3.07,1.37-4.72,1.78-1.65.4-3.35.61-5.1.61-2.94,0-5.71-.55-8.3-1.66-2.59-1.11-4.86-2.62-6.79-4.55-1.94-1.93-3.47-4.18-4.59-6.76-1.12-2.58-1.68-5.34-1.68-8.28,0-3.2.56-6.2,1.68-8.98,1.12-2.78,2.65-5.21,4.59-7.28,1.94-2.07,4.2-3.7,6.79-4.9,2.59-1.2,5.36-1.8,8.3-1.8,1.74,0,3.46.22,5.13.66,1.68.44,3.28,1.09,4.8,1.93,1.52.85,2.93,1.88,4.22,3.1,1.29,1.22,2.4,2.63,3.34,4.22Z"
          />
          <path
            class="innomactic-logo-brand"
            d="M146.26,55.6c4.64-5.45,6.25-12.46,5.01-18.98l-14.65,17.23c-5.09,5.99-14.08,6.72-20.07,1.62-5.99-5.09-6.72-14.08-1.62-20.07,5.09-5.99,14.08-6.72,20.07-1.62,2.64,2.25,4.26,5.25,4.81,8.42.22,1.25.26,2.52.14,3.78h0s10.53-12.38,10.53-12.38l4.2-4.93,1.08-1.27c.71.07,1.45-.2,1.94-.78.81-.95.69-2.37-.26-3.18-.95-.81-2.37-.69-3.18.26-.5.59-.64,1.35-.46,2.04l-4.42,5.2c-.83-1.7-1.87-3.31-3.13-4.78l-3.18,3.74c.32.66.25,1.47-.26,2.07s-1.3.8-2,.59c-.24-.07-.48-.2-.68-.37s-.36-.38-.48-.61c-.06-.13-.11-.26-.14-.39-.14-.57,0-1.19.4-1.67.51-.59,1.3-.8,2-.59l3.3-3.89,4.68-5.5,1.98-2.33c.7.21,1.49,0,2-.59.68-.8.58-2-.22-2.68s-2-.58-2.68.22c-.51.59-.58,1.41-.26,2.07l-6.6,7.76c-.15-.13-.29-.26-.45-.39-1.34-1.14-2.78-2.1-4.28-2.88l-3.44,4.05c.32.66.25,1.47-.26,2.07-.68.8-1.88.9-2.68.22-.21-.17-.36-.38-.48-.61-.32-.66-.25-1.47.26-2.07s1.3-.8,2-.59l3.18-3.74.74-.87c.47.14.98.1,1.41-.13.22-.11.42-.27.59-.46.68-.8.58-2-.22-2.68-.8-.68-2-.58-2.68.22-.51.59-.58,1.41-.26,2.07l-1.08,1.27c-8.61-3.02-18.57-.64-24.83,6.73-8.13,9.56-6.97,23.9,2.59,32.03,9.56,8.13,23.9,6.97,32.03-2.59Z"
          />
          <path
            class="innomactic-logo-main"
            d="M134.62,43.42c.15,1.19.06,2.32-.26,3.41-.32,1.09-.83,2.06-1.51,2.93-.68.86-1.52,1.59-2.51,2.17-.99.59-2.08.95-3.26,1.1-1.19.15-2.33.06-3.44-.26-1.1-.32-2.09-.81-2.95-1.48-.86-.67-1.59-1.49-2.17-2.46-.58-.97-.95-2.05-1.1-3.24s-.06-2.28.26-3.39c.32-1.1.81-2.09,1.48-2.95.67-.86,1.5-1.58,2.49-2.17.99-.58,2.08-.95,3.26-1.1s2.33-.06,3.44.26c1.1.32,2.09.81,2.97,1.48.88.67,1.61,1.49,2.19,2.48.58.99.95,2.06,1.1,3.22Z"
          />
        </g>
      </g>
    </svg>
</template>

<script>

export default {
  name: 'LogoComponent',
  props: {
    className: String,
    inverted: Boolean
  }
};
</script>