<template>
      <header className="sticky top-0 z-50 flex items-center justify-between w-full h-16 px-4 border-b shrink-0 bg-gradient-to-b from-background/10 via-background/50 to-background/80 backdrop-blur-xl">
      <div className="flex items-center justify-between w-full">
        <a href="/" target="_blank" rel="nofollow">
          <LogoComponent className="innomactic-logo" />
          <div className='hidden md:flex'>
            <!-- <IconNextChat className="innomactic-logo dark:hidden" inverted />
            <IconNextChat className="hidden innomactic-logo dark:flex" /> -->
          </div>
          <!-- <MobileChatIcon className="innomactic-logo md:hidden" /> -->
        </a>
        <ButtonComponent variant="outline" size="lg" @click="toggleDarkMode">
          <span v-if="isDarkMode">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 256"
            >
              <path d="M120 40V16a8 8 0 0 1 16 0v24a8 8 0 0 1-16 0Zm72 88a64 64 0 1 1-64-64 64.07 64.07 0 0 1 64 64Zm-16 0a48 48 0 1 0-48 48 48.05 48.05 0 0 0 48-48ZM58.34 69.66a8 8 0 0 0 11.32-11.32l-16-16a8 8 0 0 0-11.32 11.32Zm0 116.68-16 16a8 8 0 0 0 11.32 11.32l16-16a8 8 0 0 0-11.32-11.32ZM192 72a8 8 0 0 0 5.66-2.34l16-16a8 8 0 0 0-11.32-11.32l-16 16A8 8 0 0 0 192 72Zm5.66 114.34a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32-11.32ZM48 128a8 8 0 0 0-8-8H16a8 8 0 0 0 0 16h24a8 8 0 0 0 8-8Zm80 80a8 8 0 0 0-8 8v24a8 8 0 0 0 16 0v-24a8 8 0 0 0-8-8Zm112-88h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16Z" />
            </svg>
          </span>
          <span v-else>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
          >
            <path d="M233.54 142.23a8 8 0 0 0-8-2 88.08 88.08 0 0 1-109.8-109.8 8 8 0 0 0-10-10 104.84 104.84 0 0 0-52.91 37A104 104 0 0 0 136 224a103.09 103.09 0 0 0 62.52-20.88 104.84 104.84 0 0 0 37-52.91 8 8 0 0 0-1.98-7.98Zm-44.64 48.11A88 88 0 0 1 65.66 67.11a89 89 0 0 1 31.4-26A106 106 0 0 0 96 56a104.11 104.11 0 0 0 104 104 106 106 0 0 0 14.92-1.06 89 89 0 0 1-26.02 31.4Z" />
          </svg>
          </span>
      </ButtonComponent>
      </div>
    </header>
</template>


<script>

import LogoComponent from '././ui/LogoComponent.vue';
import ButtonComponent from '././ui/ButtonComponent.vue';

export default {
  name: "HeaderComponent",
  components: {
    LogoComponent,
    ButtonComponent
  },
  mounted() {
    // get from local storage
    const isDarkMode = localStorage.getItem('isDarkMode') === 'true';
    // apply to body
    this.applyMode(isDarkMode);
    // set to data
    console.log(isDarkMode);
    this.isDarkMode = isDarkMode;
  },
  data() {
    return {
      isDarkMode: localStorage.getItem('isDarkMode') === 'true',
    };
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      // save to local storage
      localStorage.setItem('isDarkMode', this.isDarkMode);
      // apply to body
      this.applyMode(this.isDarkMode);
    },
    applyMode(modeDark) {
      if (modeDark) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    },
  },
};
</script>

<style>
  svg {
    width: 20px;
    height: 20px;
    fill: #000;
  }

  .dark svg {
    fill: #fff;
  }
  
</style>