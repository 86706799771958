<template>
  <div>
    <!-- Header component, if you have one -->
    <HeaderComponent />
    <main :class="['flex', 'flex-col', 'flex-1' ]">
      <MainContent />
      <FooterComponent />
    </main>
  </div>
</template>

<script>
import MainContent from './components/MainComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    MainContent,
    FooterComponent
  },
  // data() {
  //   return {
  //     isDarkMode: false,
  //   };
  // },
  // methods: {
  //   toggleDarkMode() {
  //     this.isDarkMode = !this.isDarkMode;
  //     document.body.classList.toggle('dark');
  //   },
  // },
};
</script>

<style>
  .mode-toggle {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    cursor: pointer;
  }
</style>
