<template>
  <component :is="tag" :class="buttonClasses" v-bind="$attrs" v-on="listeners">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'ButtonComponent',
  props: {
    asChild: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default' // default, destructive, outline, secondary, ghost, link
    },
    size: {
      type: String,
      default: 'default' // default, sm, lg, icon
    }
  },
  computed: {
    tag() {
      return this.asChild ? 'span' : 'button';
    },
    buttonClasses() {
      const baseClasses = 'inline-flex items-center justify-center rounded-md text-sm font-medium shadow ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 p-5';
      
      const variantClasses = {
        default: 'bg-primary text-primary-foreground shadow-md hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'shadow-none hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 shadow-none hover:underline'
      };

      const sizeClasses = {
        default: 'h-8 px-3 py-2',
        sm: 'h-8 rounded-md px-3',
        lg: 'h-11 rounded-md px-3',
        icon: 'h-8 w-8 p-0'
      };

      return `${baseClasses} ${variantClasses[this.variant] || variantClasses.default} ${sizeClasses[this.size] || sizeClasses.default}`;
    }
  }
};
</script>
