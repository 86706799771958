<template>
  <footer class="mt-10">
    <div class="p-3 border">
      <p
        class="flex flex-col px-2 text-center text-xs leading-normal text-muted-foreground"
      >
        <span
          >AI Text to Speech provided by
          <a
            href="https://innomactic.com/"
            target="_blank"
            class="inline-flex flex-1 justify-center gap-1 leading-4 hover:underline"
            ><span>Innomactic</span
            ><svg
              aria-hidden="true"
              height="7"
              viewBox="0 0 6 6"
              width="7"
              class="opacity-70"
            >
              <path
                d="M1.25215 5.54731L0.622742 4.9179L3.78169 1.75597H1.3834L1.38936 0.890915H5.27615V4.78069H4.40513L4.41109 2.38538L1.25215 5.54731Z"
                fill="currentcolor"
              ></path></svg
          ></a> </span
        ><span
          >Developed by
          <a
            href="https://pravton.com/"
            target="_blank"
            class="inline-flex flex-1 justify-center gap-1 leading-4 hover:underline"
            ><span>Clinton</span
            ><svg
              aria-hidden="true"
              height="7"
              viewBox="0 0 6 6"
              width="7"
              class="opacity-70"
            >
              <path
                d="M1.25215 5.54731L0.622742 4.9179L3.78169 1.75597H1.3834L1.38936 0.890915H5.27615V4.78069H4.40513L4.41109 2.38538L1.25215 5.54731Z"
                fill="currentcolor"
              ></path></svg></a
        ></span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style>
  footer {
      svg {
      width: 10px;
      height: 10px;
    }
  }
</style>
